<template>
  <div class="label"><slot></slot></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.label {
  font-size: 12px;
  line-height: 12px;
  color: $secondaryTextColor;
  margin-bottom: 7px;
  user-select: none;
}
</style>
