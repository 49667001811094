<template>
  <div
    class="tile"
    :class="{ tile_active: value, tile_counter: typeof value === 'number' }"
    v-tooltip="{ content: tooltip, show: showTooltip }"
    v-click-outside="hideTooltip"
    v-body-scroll="hideTooltip"
    @click="toggle"
  >
    <img class="icon" :src="icon" />
    <span class="title">{{ title }}</span>
    <span class="subtitle">{{ time }}</span>

    <div
      class="price"
    >
      <span style="padding-right: 3px;" v-if="title == 'Полировка мебели'">от </span>
      {{ price * value || price }} ₽
   </div>
    <div v-if="typeof value === 'number' && value > 0" class="counter">
      <button
        class="counter__button counter__button_minus"
        @click.stop="changeValue(-1)"
      >
        -
      </button>
      {{ value }}
      <button class="counter__button" @click.stop="changeValue(1)">+</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['icon', 'title', 'price', 'value', 'tooltip', 'time'],
  data() {
    return {
      showTooltip: false,
      isTouchDevice: 'ontouchstart' in window,
    };
  },
  methods: {
    hideTooltip() {
      if (!this.isTouchDevice) {
        return;
      }
      this.showTooltip = false;
    },
    toggle() {
      if (!this.showTooltip && this.isTouchDevice) {
        this.showTooltip = true;
      }

      if (typeof this.value === 'boolean') {
        this.$emit('input', !this.value);
        return;
      }

      if (typeof this.value !== 'number') {
        return;
      }

      if (this.value === 0) {
        this.$emit('input', 1);
        return;
      }

      this.$emit('input', 0);
    },
    changeValue(addendum) {
      const newVal = this.value + addendum;
      if (newVal > 20) {
        return;
      }
      this.$emit('input', newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.tile {
  display: flex;
  flex-direction: column;
  width: $tileWidth;
  flex: 0 0 $tileWidth;
  height: 155px;
  border: $border;
  border-radius: $borderRadius;
  text-align: center;
  cursor: pointer;
  user-select: none;
  background: #fff;

  @include lg {
    flex: 0 0 $lgTileWidth;
    width: $lgTileWidth;
  }

  @include md {
    flex: 0 0 $mdTileWidth;
    width: $mdTileWidth;
  }

  @include sm {
    flex: 0 0 $smTileWidth;
    width: $smTileWidth;
  }

  &_active {
   border-color: #88dcef;

    .price,
    .counter {
      border-color: $secondaryColor;
      color: #000;
      font-weight: bold;
    }

    @media (hover: none) {
      &.tile_counter {
        .price {
          display: none;
        }

        .counter {
          display: flex;
        }
      }
    }

    @include hover {
      &.tile_counter:hover {
        .price {
          display: none;
        }

        .counter {
          display: flex;
        }
      }
    }
  }

  &_nopointer {
    cursor: default;
  }
}

.icon {
  margin: auto 0;
  flex-basis: 0;
  width: 35px;
  align-self: center;
}

.subtitle {
  font-size: 10px;
  color: rgba(0,0,0,.4);
  padding-top: 7px;
}

.title {
  font-size: 14px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  word-break: break-word;

  @include sm {
    padding: 0 5px;
    font-size: 13px;
  }
}

.price,
.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  color: $secondaryTextColor;
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 5px;
}

.counter {
  display: none;

  &__button {
    flex: 0 0 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 100%;
    padding: 0;
    border: none;
    cursor: pointer;
    margin: 0 7px;
    font-weight: bold;
    line-height: 15px;
    touch-action: manipulation;

    @include sm {
      flex: 0 0 20px;
      height: 20px;
    }

    &_minus {
      line-height: 16px;
    }

    &:focus {
      outline: none;
    }
  }
}
</style>
