/* eslint-disable global-require */
export default {
  refrigerator: {
    icon: require('@/images/refrigerator.svg'),
    title: 'Холодильник',
    price: 500,
    time: '40 мин.',
    tooltip:
      'Освободим полки, отмоем грязь и выбросим испорченные продукты с вашего разрешения. Заранее разморозьте морозилку, если хотите, чтобы мы ее помыли.',
  },
  balcony: {
    icon: require('@/images/balcony.svg'),
    title: 'Балкон',
    countable: true,
    price: 1000,
    tooltip:
      'Уборка остекленных балконов. Протрем все доступные поверхности, пол и плинтуса. Мытье окон входит в стоимость.',
  },
  chandellier: {
    icon: require('@/images/chandellier.svg'),
    title: 'Люстра',
    countable: true,
    price: 500,
    tooltip: 'Бережно протрем пыль.',
  },
  cupboards: {
    icon: require('@/images/cupboard.svg'),
    title: 'Внутри шкафов',
    price: 700,
    time: '60 мин.',
    tooltip: 'Освободим полки и отмоем грязь.',
  },
  dishes: {
    icon: require('@/images/dishes.svg'),
    title: 'Посуда',
    price: 300,
    tooltip: 'Моем посуду дополнительно 30 минут.',
  },
  hood: {
    icon: require('@/images/hood.svg'),
    title: 'Вытяжка',
    price: 400,
    tooltip: 'Отмоем грязь и жир изнутри.',
    time: '.',
  },
  ironing: {
    icon: require('@/images/iron.svg'),
    title: 'Глажка',
    price: 600,
    tooltip: 'Просто оставьте вещи  и утюг на видном месте. Входит 60 минут работы.',
  },
  keys: {
    icon: require('@/images/keys.svg'),
    title: 'Забрать ключи',
    price: 500,
  },
  microwave: {
    icon: require('@/images/microwave.svg'),
    title: 'Микроволновка',
    price: 300,
    time: '30 мин.',
    tooltip: 'Отмоем грязь и жир изнутри.',
  },
  oven: {
    icon: require('@/images/oven.svg'),
    title: 'Духовка',
    price: 500,
    time: '40 мин.',
    tooltip: 'Отмоем грязь и жир изнутри.',
  },
  windows: {
    icon: require('@/images/windows.svg'),
    title: 'Окна',
    countable: true,
    price: 500,
    tooltip: 'Помоем остекление, рамы, откосы и подоконник. Одно окно - один проем в стене.',
  },
  dishwasher: {
    icon: require('@/images/dishwasher.svg'),
    title: 'Посудомойка',
    price: 300,
    time: '30 мин.',
  },
  polishing: {
    icon: require('@/images/polishing.svg'),
    title: 'Полировка мебели',
    price: 300,
    time: '.',
  },
};
