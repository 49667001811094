<template>
  <div class="wrapper">
    <button class="sign sign_minus" @click="updateValue(value - step)"></button>
    <VLabel>{{ label }}</VLabel>
    <input
      v-model="localValue"
      @change="updateValue($event.target.value)"
      class="input"
      type="number"
    />
    <button class="sign sign_plus" @click="updateValue(value + step)"></button>
  </div>
</template>

<script>
import VLabel from '@/components/VLabel.vue';

export default {
  components: {
    VLabel,
  },
  props: ['value', 'label', 'min', 'max', 'step'],
  data() {
    return {
      localValue: this.value,
    };
  },
  watch: {
    value() {
      this.localValue = this.value;
    },
  },
  methods: {
    updateValue(newValue) {
      const value = parseInt(newValue, 10);

      if (Number.isNaN(value) || value < this.min) {
        this.localValue = this.min;
        this.$emit('input', this.min);
        return;
      }

      if (value > this.max) {
        this.localValue = this.max;
        this.$emit('input', this.max);
        return;
      }

      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  border: $border;
  border-radius: $borderRadius;
  text-align: center;
  padding: $formItemPadding;
  padding-bottom: 8px;
  background: #fff;

  @include sm {
    padding: $smFormItemPadding;
  }
}

.input {
  appearance: textfield;
  display: block;
  width: 100%;
  border: none;
  font-size: 16px;
  height: 17px;
  overflow: visible;
  padding: 0 45px;
  margin: 0;
  text-align: center;

  &:focus {
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }

  @include sm {
    margin-top: -1px;
    position: relative;
    top: -2px;
  }
}

.sign {
  position: absolute;
  border: none;
  background: none;
  padding: 0;
  height: 100%;
  width: 61px;
  cursor: pointer;
  top: 0;
  touch-action: manipulation;
  z-index: 1;

  &_minus {
    border-right: $border;
    left: 0;
  }

  &_plus {
    border-left: $border;
    right: 0;

    &:after {
      transform: rotate(90deg);
    }
  }

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 16px;
    height: 1px;
    background-color: #000;
  }

  &:focus {
    outline: none;
  }
}
</style>
