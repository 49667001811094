<template>
  <div
    class="pill"
    :class="{ pill_bg: withBg, pill_center: textCenter, pill_small: small }"
  >
    <VLabel>{{ label }}</VLabel>
    <div>{{ text }}</div>
  </div>
</template>

<script>
import VLabel from '@/components/VLabel.vue';

export default {
  props: {
    label: String,
    text: String,
    withBg: Boolean,
    textCenter: Boolean,
    small: Boolean,
  },
  components: {
    VLabel,
  },
};
</script>

<style lang="scss" scoped>
.pill {
  padding: $formItemPadding;
  border-radius: $borderRadius;

  &_bg {
    background-color: #fff;
  }

  @include sm {
    padding: $smFormItemPadding;
  }

  &:not(.pill_bg) {
    @include sm {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  &_center {
    text-align: center;
  }

  &_small {
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;

    @include lg {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
</style>
