<template>
    <a href="#popup:myform2" id="btn-order" class="button" style="color: #fff;">
        <slot></slot>
    </a>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.button {
    text-align: center;
    background: #F99746;
    border-radius: 30px;
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: .3s;
    text-decoration: none;
    margin: auto;
    padding: 20px 0px;
    max-width: 350px;

    @include hover {
        background: #fa8525;
    }
}
</style>
