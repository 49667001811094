<template>
  <div id="app">
    <div class="inputs">
      <VSelect
        class="inputs__item"
        label="Услуга"
        v-model="currentServiceKey"
        :options="servicesOptions"
        :tooltip="currentService.tooltip"
      />

      <template v-for="input in currentService.inputs">
        <VSelect
          v-if="input.type === 'select'"
          class="inputs__item"
          :label="input.label"
          :disabled="input.disabled"
          v-model="input.value"
          :options="getInputOptions(input.options)"
          v-tooltip="input.tooltip"
          :key="input.key"
        />
        <VCheckbox
          v-else-if="input.type === 'checkbox'"
          class="inputs__item"
          :label="input.label"
          :title="input.title"
          v-model="input.value"
          :disabled="input.disabled"
          :tooltip="input.tooltip"
          :key="input.key"
        />
      </template>
    </div>

    <div class="inputs">
      <template v-for="input in filteredPremises">
        <VSelect
          v-if="input.type === 'select'"
          class="inputs__item"
          :disabled="input.disabled"
          :label="input.label"
          v-model="input.value"
          :options="getInputOptions(input.options)"
          v-tooltip="input.tooltip"
          :key="input.key"
        />
        <VNumberInput
          v-else-if="input.type === 'number'"
          class="inputs__item"
          :label="input.label"
          v-model="input.value"
          v-tooltip="input.tooltip"
          :step="input.step"
          :min="input.min"
          :max="input.max"
          :key="input.key"
        />
      </template>
    </div>

    <template v-if="currentService.extras && currentService.extras.length">
      <span class="heading">{{
        currentService.extrasTitle || "Дополнительные услуги:"
      }}</span>
      <div class="tiles">
        <VTile
          class="tiles__item"
          v-for="extra in currentService.extras"
          :key="extra.key"
          :icon="extras[extra.key].icon"
          :time="extras[extra.key].time"
          :title="extras[extra.key].title"
          :price="extras[extra.key].price"
          :tooltip="extra.tooltip || extras[extra.key].tooltip"
          v-model="extra.value"
        />
      </div>
    </template>

    <div class="total">
      <VPill
        class="total__item" id="total-calc" label="Стоимость уборки" :text="price + ' ₽'" with-bg />
      <VCheckbox
        v-if="currentService.totalCheckbox"
        class="total__item"
        :label="currentService.totalCheckbox.label"
        :title="currentService.totalCheckbox.title"
        :tooltip="currentService.totalCheckbox.tooltip"
        v-model="currentService.totalCheckbox.value"
        small
      />
      <VPill
        class="total__item"
        label="Время уборки"
        :text="duration"
        text-center
        small
      />
      <VButton class="total__button">
        Заказать уборку
      </VButton>
    </div>
  </div>
</template>

<script>
import services from '@/services';
import extras from '@/extras';
import premises from '@/premises';

import VSelect from './components/VSelect.vue';
import VCheckbox from './components/VCheckbox.vue';
import VNumberInput from './components/VNumberInput.vue';
import VTile from './components/VTile.vue';
import VPill from './components/VPill.vue';
import VButton from './components/VButton.vue';

const processedServices = services.map((service) => ({
  ...service,
  extras: service.extras.map((extraKey) => {
    const extra = {
      key: extraKey,
      value: extras[extraKey].countable ? 0 : false,
    };

    if (service.extrasIncluded) {
      extra.tooltip = 'Включено в стоимость.';
      extra.value = true;
    }

    return extra;
  }),
}));

export default {
  name: 'App',
  components: {
    VSelect,
    VCheckbox,
    VNumberInput,
    VTile,
    VPill,
    VButton,
  },
  data() {
    return {
      services: processedServices,
      premises,
      extras,
      currentServiceKey: window.DEFAULT_CALCULATOR_SERVICE || 'maintenance',
    };
  },
  computed: {
    servicesOptions() {
      return services.reduce((options, service) => ({ ...options, [service.key]: service.title }),
        {});
    },
    currentService() {
      return this.services.find((service) => service.key === this.currentServiceKey);
    },
    premisesTypeOption() {
      const typeInput = this.premises.find((input) => input.key === 'type');
      return typeInput.options.find((option) => option.value === typeInput.value);
    },
    premisesType() {
      return this.premisesTypeOption.value;
    },
    footageType() {
      const footageType = this.currentService.footageType || this.premisesTypeOption.footageType;

      return footageType;
    },
    filteredPremises() {
      const typeInput = this.premises.find((input) => input.key === 'type');

      return [typeInput,
        ...this.premises.filter((input) => input.footageType === this.footageType)];
    },
    priceForFootage() {
      let footage = this.premises
        .filter((input) => input.footageType === this.footageType)
        .reduce((sum, input) => sum + parseInt(input.value, 10), 0);

      if (this.footageType === 'rooms') {
        footage -= 2;
      }

      const config = this.currentService.price[this.footageType];

      const additionalModifier = this.currentService.inputs.reduce((sum, input) => {
        if (input.type !== 'checkbox' || !input.value || typeof input.price !== 'string') {
          return sum;
        }

        const match = input.price.match(/additional(\d+)/);
        if (!match) {
          return sum;
        }

        return sum + parseInt(match[1], 10);
      }, 0);

      return (config.base || 0) + footage * (config.additional + additionalModifier);
    },
    price() {
      const result = this.currentService.inputs.reduce((config, input) => {
        const newConfig = { ...config };

        if (input.type === 'select') {
          const option = input.options.find((opt) => opt.value === input.value);

          if (!option.price) {
            return config;
          }

          if (typeof option.price === 'number') {
            newConfig.price += option.price;
            return newConfig;
          }

          if (/%$/.test(option.price)) {
            newConfig.modifier += parseInt(option.price, 10) / 100;
            return newConfig;
          }

          return config;
        }

        if (input.type !== 'checkbox' || !input.value) {
          return config;
        }

        if (typeof input.price === 'number') {
          newConfig.price += input.price;
          return newConfig;
        }

        if (/%$/.test(input.price)) {
          newConfig.modifier += parseInt(input.price, 10) / 100;
          return newConfig;
        }

        return config;
      }, {
        price: this.priceForFootage,
        modifier: 1,
      });

      result.price += !this.currentService.extrasIncluded
        && this.currentService.extras.reduce((sum, extra) => {
          if (!extra.value) {
            return sum;
          }

          return sum + extra.value * this.extras[extra.key].price;
        }, 0);

      const { totalCheckbox } = this.currentService;

      if (totalCheckbox && totalCheckbox.value) {
        if (typeof totalCheckbox.price === 'number') {
          result.price += totalCheckbox.price;
        }

        if (/%$/.test(totalCheckbox.price)) {
          result.modifier += parseInt(totalCheckbox.price, 10) / 100;
        }
      }

      return Math.round(result.price * result.modifier);
    },
    duration() {
      return this.currentService.duration[this.premisesType]
      || this.currentService.duration.fallback;
    },
  },
  watch: {
    currentServiceKey: {
      handler: 'prepareCustomInputs',
      immediate: true,
    },
    premisesType() {
      this.prepareCustomInputs();
    },
  },
  methods: {
    prepareCustomInputs() {
      this.currentService.inputs.forEach((input, idx) => {
        if (!input.custom) {
          return;
        }

        const customInfo = input.custom[this.premisesType] || {};
        Object.keys(customInfo).forEach((key) => {
          this.currentService.inputs[idx][key] = customInfo[key];
        });
      });
    },
    getInputOptions(rawOptions) {
      return rawOptions
        .reduce((options, rawOption) => ({ ...options, [rawOption.value]: rawOption.title }), {});
    },
    order() {
      const order = {
        service: this.currentService.title,
        duration: this.duration,
      };

      [...this.currentService.inputs, ...this.filteredPremises].forEach((input) => {
        order[input.key] = input.type === 'select' ? input.options.find((option) => option.value === input.value).title : input.value;
      });

      order.extras = this.currentService.extras
        .filter((extra) => extra.value)
        .map((extra) => (this.extras[extra.key].title + (typeof extra.value === 'number' ? ` ${extra.value}` : '')));

      const { totalCheckbox } = this.currentService;
      order[totalCheckbox.key] = totalCheckbox.value;

      order.price = this.price;

      const event = new CustomEvent('calculator', { detail: order });
      window.dispatchEvent(event);
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/global.scss";
</style>

<style lang="scss" scoped>

#app {
  font-size: 16px;
  max-width: 1160px;
  margin: 0 auto;
  background-color: #e0f5ff;
  padding: 30px;
  border-radius: 5px;
}

.inputs {
  display: flex;

  @include sm {
    flex-wrap: wrap;
  }

  &__item {
    flex: 1 0 0;
    margin-bottom: $formItemVerticalMargin;

    @include sm {
      margin-bottom: $smFormItemVerticalMargin;

      &:first-child {
        flex: 0 0 100%;
      }
    }

    &:not(:last-child) {
      margin-right: $formItemHorizontalMargin;

      @include sm {
        margin-right: 0;
      }
    }
  }

  &:nth-child(2) {
    .inputs__item:nth-child(2):not(:last-child) {
      @include sm {
        margin-right: $smFormItemVerticalMargin;
      }
    }
  }
}

.heading {
  display: block;
  margin: 10px 0 $formItemVerticalMargin;

  @include sm {
    margin-bottom: $smFormItemVerticalMargin;
  }
}

.tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__item {
    margin-bottom: $formItemVerticalMargin;

    @include sm {
      margin-bottom: 10px;
    }
  }

  &__item:not(:nth-child(#{$tileCount}n)) {

    @include lg {
    }

    @include md {
    }
  }

  &__item:not(:nth-child(#{$mdTileCount}n)) {
    @include md {
      margin-right: calc(
        (100% - #{$mdTileWidth * $mdTileCount}) / #{$mdTileCount - 1}
      );
    }

    @include sm {
      margin-right: 0;
    }
  }

  &__item:not(:nth-child(#{$smTileCount}n)) {
    @include sm {
      margin-right: calc(
        (100% - #{$smTileWidth * $smTileCount}) / #{$smTileCount - 1}
      );
    }
  }
}

.total {
  display: flex;

  @include md {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    flex: 0 0 $tileWidth;
    margin-right: $formItemHorizontalMargin;

    &:first-child {
      @include lg {
        flex: 0 0 130px;
      }

      @include md {
        flex: 1 0 0;
      }
    }

    &:nth-child(2) {
      @include md {
        flex: 1 0 0;
      }
    }

    &:first-child,
    &:nth-child(2) {
      @include sm {
        flex: 0 0 49%;
      }
    }

    &:nth-child(3) {
      @include sm {
        flex: 100%;
      }
    }

    @include lg {
      flex: 0 0 auto;
      margin-right: 10px;
    }

    @include md {
      flex: 0 0 auto;
    }

    @include sm {
      flex: 0 0 49%;
      margin-right: 0;
    }
  }

  &__button {
    flex-grow: 1;

    @include md {
      flex: 0 0 100%;
      height: 50px;
      margin-top: 20px;
    }

    @include sm {
      margin-top: 10px;
    }

    &:focus {
      outline: none;
    }
  }
}
</style>
