import Vue from 'vue';
import VTooltip from 'v-tooltip';
import VClickOutside from 'vue-click-outside';
import App from './App.vue';

Vue.directive('click-outside', VClickOutside);
Vue.directive('body-scroll', {
  bind(el, binding) {
    window.addEventListener('scroll', () => {
      setTimeout(binding.value, 0);
    }, { passive: true });
  },
});
Vue.use(VTooltip, {
  defaultTrigger: 'ontouchstart' in window ? 'manual' : 'hover focus',
  defaultHideOnTargetClick: false,
});

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount('#calculator');
