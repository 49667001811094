export default [
  {
    key: 'maintenance',
    title: 'Поддерживающая уборка',
    price: {
      rooms: {
        base: 3600,
        additional: 600,
      },
      metres: {
        additional: 85,
      },
    },
    extras: [
      'refrigerator',
      'oven',
      'microwave',
      'dishwasher',
      'hood',
      'polishing',
      'cupboards',
    ],
    duration: {
      flat: 'От 2.5 до 3 часов',
      fallback: 'Уточняйте по телефону',
    },
    inputs: [
      {
        type: 'select',
        key: 'frequency',
        label: 'Периодичность',
        value: 'once',
        options: [
          {
            value: 'once',
            title: '1 раз',
          },
          {
            value: 'biweekly',
            title: 'Раз в две недели (-10%)',
            price: '-10%',
          },
          {
            value: 'weekly',
            title: 'Раз в неделю (-20%)',
            price: '-20%',
          },
        ],
      },
    ],
    totalCheckbox: {
      key: 'eco',
      label: '+40% к сумме',
      title: 'Эко уборка',
      value: false,
      price: '+40%',
      tooltip: 'Уборка безопасными средствами для людей с аллергией, детей и домашних животных.',
    },
  },
  {
    key: 'spring',
    title: 'Генеральная уборка',
    price: {
      rooms: {
        base: 9000,
        additional: 2000,
      },
      metres: {
        additional: 175,
      },
    },
    extras: [
      'refrigerator',
      'oven',
      'microwave',
      'dishwasher',
      'hood',
      'polishing',
      'cupboards',
    ],
    duration: {
      flat: 'От 4 до 6 часов',
      fallback: 'Уточняйте по телефону',
    },
    tooltip:
      'Приедем с профессиональным пылесосом и парогенератором Karcher,  дополнительным набором химии для выведения сложных загрязнений и стремянкой.',
    inputs: [],
  },
  {
    key: 'renovation',
    title: 'Уборка после ремонта',
    price: {
      rooms: {
        base: 12000,
        additional: 3000,
      },
      metres: {
        additional: 175,
      },
    },
    extras: [
      'cupboards',
    ],
    duration: {
      fallback: 'От 4 до 6 часов',
    },
    tooltip:
      'Приедем с большим профессиональным пылесосом и парогенератором Karcher, дополнительным набором химии для выведения сложных загрязнений и стремянкой. Уберем послестроительный мусор, следы цемента, скотча и краски.',
    inputs: [
    ],
    totalCheckbox: {
      key: 'furniture',
      label: '+20% к сумме',
      title: 'Есть мебель',
      value: false,
      price: '+20%',
      tooltip: 'Выдвинем всю мебель, которую можно подвинуть и тщательно помоем под ней.',
    },
  },
];
