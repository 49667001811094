<template>
  <div
    class="wrapper"
    :class="{ wrapper_disabled: disabled }"
    @click="toggle"
    v-click-outside="hideTooltip"
    v-body-scroll="hideTooltip"
    v-tooltip="{ content: tooltip, show: showTooltip }"
  >
    <div class="checkbox" :class="{ checkbox_checked: value }"></div>
    <div class="text" :class="{ text_small: small }">
      <VLabel class="text__label">{{ label }}</VLabel>
      <div>{{ title }}</div>
    </div>
  </div>
</template>

<script>
import VLabel from '@/components/VLabel.vue';

export default {
  components: {
    VLabel,
  },
  props: {
    label: String,
    title: String,
    value: Boolean,
    disabled: Boolean,
    small: Boolean,
    tooltip: String,
  },
  data() {
    return {
      showTooltip: false,
      isTouchDevice: 'ontouchstart' in window,
    };
  },
  methods: {
    toggle() {
      this.showTooltip = true;

      if (this.disabled) {
        return;
      }
      this.$emit('input', !this.value);
    },
    hideTooltip() {
      if (!this.isTouchDevice) {
        return;
      }
      this.showTooltip = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  border-radius: $borderRadius;
  padding: $formItemPadding;
  border: $border;
  cursor: pointer;
  user-select: none;
  background: #fff;

  @include sm {
    padding: $smFormItemPadding;
  }

  &_disabled {
    cursor: default;
  }
}

.checkbox {
  position: relative;
  width: 24px;
  height: 24px;
  border: $border;
  border-color: #88dcef;
  margin-right: $formItemHorizontalGap;

  &_checked {
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background: #7fd5dd;
      width: 12px;
      height: 12px;
    }
  }
}

.text {
  &_small {
    font-size: 14px;

    .text__label {
      margin-bottom: 5px;
    }
  }
}

</style>
