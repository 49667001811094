<template>
  <div
    class="wrapper"
    v-tooltip="{ content: tooltip, show: showTooltip }"
    v-click-outside="hideTooltip"
    v-body-scroll="hideTooltip"
    @click="showTooltip = true"
  >
    <VLabel>{{ label }}</VLabel>
    <div>{{ options[value] }}</div>

    <select
      class="select"
      :class="{ select_disabled: disabled }"
      :value="value"
      @change="$emit('input', $event.target.value)"
      :disabled="disabled"
    >
      <option
        v-for="(option, optionValue) in options"
        :value="optionValue"
        :key="optionValue"
      >
        {{ option }}
      </option>
    </select>
    <img class="icon" src="@/images/select.svg" />
  </div>
</template>

<script>
import VLabel from '@/components/VLabel.vue';

export default {
  components: {
    VLabel,
  },
  props: ['label', 'options', 'value', 'disabled', 'tooltip'],
  data() {
    return {
      showTooltip: false,
      isTouchDevice: 'ontouchstart' in window,
    };
  },
  methods: {
    hideTooltip() {
      if (!this.isTouchDevice) {
        return;
      }
      this.showTooltip = false;
    },
  },
  watch: {
    value() {
      this.showTooltip = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  border: $border;
  background: #fff;
  border-radius: $borderRadius;
  padding: $formItemPadding;

  @include sm {
    padding: $smFormItemPadding;
  }
}

.select {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  z-index: 1;
  opacity: 0;
  font-size: 16px;
  cursor: pointer;
  user-select: none;

  &_disabled {
    cursor: default;

    & + .icon {
      display: none;
    }
  }
}

.icon {
  position: absolute;
  right: 24px;
  top: 0;
  bottom: 0;
  margin: auto;
}
</style>
