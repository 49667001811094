export default [
  {
    type: 'select',
    key: 'type',
    label: 'Тип помещения',
    value: 'flat',
    options: [
      {
        value: 'flat',
        footageType: 'rooms',
        title: 'Квартира',
      },
      {
        value: 'house',
        footageType: 'metres',
        title: 'Коттедж/таунхаус',
      },
    ],
  },
  {
    type: 'select',
    footageType: 'rooms',
    key: 'rooms',
    label: 'Комнаты',
    value: '1',
    options: [
      {
        value: '1',
        title: '1 комната',
      },
      {
        value: '2',
        title: '2 комнаты',
      },
      {
        value: '3',
        title: '3 комнаты',
      },
      {
        value: '4',
        title: '4 комнаты',
      },
    ],
  },
  {
    type: 'number',
    footageType: 'metres',
    key: 'footage',
    label: 'Площадь, м²',
    min: 40,
    max: 1000,
    step: 10,
    value: 40,
  },
];
